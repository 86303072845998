<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :items="items" :fields="fields" @load="load" @reset="reset"
                     title="Hỗ trợ hòa nhập" titleIcon="fa fa-user-injured">
                <template slot="filter">
                    <client-select v-if='isPrime() || isTW() ||  isUsaid()' attribute="client_id"  :model="filterFormModel" :errors="formErrors"  :filter="{parent_id: $store.state.client.id}" :is-filter='true' :placeHolder="isPrime() ? 'Chọn đơn vị thầu phụ' : 'Chọn đơn vị thầu chính'" class="mr-2" @change='searchClient' />
                    <client-select v-if='(isTW() ||  isUsaid()) && filterFormModel.client_id' attribute="client_sub_id"  :model="filterFormModel" :errors="formErrors" placeHolder="Chọn đơn vị thầu phụ"  :filter="{parent_id: filterFormModel.client_id}" :is-filter='true' class="mr-2" @change='search' />
                    <patient-select :model="filterFormModel" attribute="patient_id"  :is-filter='true'  class="mr-2"  @change='search' :filter="{client_id: filterFormModel.client_id}" />
                    <dropdown   :right-menu="true" type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="mine"  :options="$params.patient.mineVictimOptions" :isFilter="true" @change="search" />
                    <dropdown type="bordered" class="mr-2" :model="filterFormModel" attribute="result" :options="$params.patient.integrateOptions" :isFilter="true" @change="search" />
                    <time-range-picker class="mr-1" :model="filterFormModel" label="Ngày can thiệp gần nhất" fromAttribute="from_rate_time" toAttribute="to_rate_time" :defaultShortcut="10" @change="search" />
                </template>
                <template #cell(client_id)="{item}">
                    <span v-if='params.clients[item.client_id]' >{{params.clients[item.client_id].short_name}}</span>
                </template>
                <template #cell(info)="{item}">
                    <div v-if="item.patient_id && params.patient_id[item.patient_id] != null"><span class="small text-gray-999">Mã: </span>{{params.patient_id[item.patient_id].code}}</div>
                    <div v-if="item.patient_id && params.patient_id[item.patient_id] != null"><span class="small text-gray-999">Họ và tên: </span>{{params.patient_id[item.patient_id].fullname}}</div>
                </template>
                <template #cell(types)="{item}">
                    <div v-if="params.types[params.patient_id[item.patient_id].id]" ><span class="small text-gray-999">Dạng khuyết tật: </span> 
                        <span v-for="tys_id in params.types[params.patient_id[item.patient_id].id]" :key="tys_id" >
                            <span class="badge bg-gray ml-1" v-if='$params.patient.typeOptions.find(o => o.value === tys_id)'>{{ $params.patient.typeOptions.find(o => o.value === tys_id).text}}</span>
                        </span>
                    </div>
                    <div v-if="params.patient_id[item.patient_id].agent_orange_victim" ><span class="small text-gray-999">Nạn nhân da cam: </span> 
                        <span v-if='$params.patient.victimOptions.find(o => o.value === params.patient_id[item.patient_id].agent_orange_victim)'>{{ $params.patient.victimOptions.find(o => o.value === params.patient_id[item.patient_id].agent_orange_victim).text}}</span>
                    </div>
                    <div v-if="params.patient_id[item.patient_id].degree_disability" ><span class="small text-gray-999">Mức độ khuyết tật: </span> 
                        <span v-if='$params.patient.degreeDisabilityOptions.find(o => o.value === params.patient_id[item.patient_id].degree_disability)'>{{ $params.patient.degreeDisabilityOptions.find(o => o.value === params.patient_id[item.patient_id].degree_disability).text}}</span>
                    </div>
                    <div v-if="params.patient_id[item.patient_id].mine_victim" ><span class="small text-gray-999">Nạn nhân bom mìn: </span> 
                        <span   v-if='$params.patient.mineVictimOptions.find(o => o.value === params.patient_id[item.patient_id].mine_victim)'>{{ $params.patient.mineVictimOptions.find(o => o.value === params.patient_id[item.patient_id].mine_victim).text}}</span>
                    </div>
                </template>
                <template #cell(result)="{item}">
                    <div v-if="item.result">
                        <span v-if='$params.patient.integrateOptions.find(o => o.value === item.result)'>{{ $params.patient.integrateOptions.find(o => o.value === item.result).text}}</span>
                    </div>
                </template>
                <template #cell(time)="{item}">
                    <div v-if="item.start_time"><span class="small text-gray-999">Ngày bắt đầu can thiệp: </span>{{item.start_time * 1000 |  moment('DD/MM/YYYY')}}</div>
                    <div v-if="item.end_time"><span class="small text-gray-999">Ngày kết thúc can thiệp: </span>{{item.end_time * 1000 |  moment('DD/MM/YYYY')}}</div>
                    <div v-if="item.rate_time"><span class="small text-gray-999">Ngày can thiệp gần nhất: </span>{{item.rate_time * 1000 |  moment('DD/MM/YYYY')}}</div>
                    <div v-if="item.evaluate_time"><span class="small text-gray-999">Ngày đánh giá gần nhất: </span>{{item.evaluate_time * 1000 |  moment('DD/MM/YYYY')}}</div>
                </template>
                <template slot="action">
                    <export :filter="filterFormModel" :params='params' :prime="isPrime() || isTW() || isUsaid()" :totalCount="itemCount" class="mr-1"/>
                    <g-button class="ml-1 btn btn-gray btn-sm" icon="fa fa-file-excel" v-if="isSub()" :loading="saveLoading" size="sm" variant="success" content="Tải xuống tập tin mẫu" @click="downloadTemplate()" />
                    <import v-if="isSub() && permissions('patient-integrate_import')" :client_id="params.clientId" :params='params' class="mr-1"/>
                    <g-button class="ml-2" v-if="permissions('patient-integrate_create') && isSub()" :loading="saveLoading" size="sm" variant="success" icon="plus" content="Thêm mới" @click='create' />
                </template>
                <template #cell(action)="{item}">
                    <g-button v-if="permissions('patient-integrate_view')" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-eye" @click="view(item.id)" />
                    <g-button v-if="permissions('patient-integrate_update') && isSub()" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="pencil"  @click="update(item.id)" />
                    <g-button  v-if="permissions('patient-integrate_del') && isSub()" :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)' />
                    <log type="patient-integrate" :targetId="item.id" title="Lịch sử" :isStaff="true" class="ml-1 mb-1" ></log>
                </template>
            </g-table>
        </div>
        <b-modal v-model="showForm" size='xl' :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md-4'>
                    <patient-select label="NKT được hỗ trợ hòa nhập" :disabled="formModel.id" :model="formModel" :filter="{parent_id: $store.state.client.id}" attribute="patient_id" @change="changePatient('patient_id')"  :errors="formErrors"></patient-select>
                </div>
                <div class='col-md-4'>
                    <form-input label="Mã NKT" :disabled="formModel.patient_id" :required='true' :model="formModel" attribute="code" @change="changePatient('code')" :errors="formErrors"></form-input>
                </div>
                <div class='col-md-4'>
                    <form-input label="Họ và tên" :disabled="formModel.patient_id" :required='true' :model="formModel" attribute="fullname"  :errors="formErrors" ></form-input>
                </div>
            </div>
            <div class='row'>
                <div class='col-md'>
                    <form-date-time label="Năm sinh" :disabled="formModel.patient_id" :required='true' :model="formModel" :errors="formErrors" attribute="dob" format="YYYY" placeholder="YYYY" />
                </div>
                <div class='col-md'>
                    <form-select label="Giới tính" :disabled="formModel.patient_id" :required='true' :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender" />
                </div>
                <div class='col-md'>
                    <parameter-select label="Dân tộc" :disabled="formModel.patient_id" :type="$params.const.race.key" :model="formModel" attribute="race_id" />
                </div>
            </div> 
            <div class='row'>
                <div class='col-md'>
                    <form-group-address class="pr-1" :model="formModel" :errors="formErrors" :provinceRequired='true' :districtRequired='true' :wardRequired='true' :provinceDisabled='formModel.patient_id ? true : false' :districtDisabled='formModel.patient_id ? true : false' :wardDisabled="formModel.patient_id ? true : false"></form-group-address>
                </div>
            </div>
            <div class='row'>
                <div class='col-md'>
                    <form-select-multiple :close-on-select='false'  :disabled="formModel.patient_id" :required='true' label="Dạng khuyết tật" :model="formModel" attribute="types" :errors="formErrors" :options="patientTypeOptions" />
                </div>
                <div class='col-md'>
                    <form-select label="Mức độ khuyết tật" :disabled="formModel.patient_id" :required='true' :model="formModel" attribute="degree_disability" :errors="formErrors" :options="$params.patient.degreeDisabilityOptions" />
                </div>
                <div class='col-md'>
                    <form-select label="Nạn nhân da cam" :disabled="formModel.patient_id" :required='true' :model="formModel" attribute="agent_orange_victim" :errors="formErrors" :options="$params.patient.victimOptions" />
                </div>
            </div> 
            <div class='row'>
                <div class='col-md-4'>
                    <form-select label="Nạn nhân bom mìn" :disabled="formModel.patient_id" :required='false' :model="formModel" attribute="mine_victim" :errors="formErrors" :options="$params.patient.mineVictimOptions" />
                </div>
                <div class='col-md'>
                    <form-date-time label="Ngày bắt đầu can thiệp" :model="formModel" :errors="formErrors" attribute="start_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
                <div class='col-md'>
                    <form-date-time label="Ngày can thiệp gần nhất" :required='true' :model="formModel" :errors="formErrors" attribute="rate_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
            </div>
            <div class='row'>
                <div class='col-md-4'>
                    <form-date-time label="Ngày kết thúc can thiệp" :model="formModel" :errors="formErrors" attribute="end_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
                <div class='col-md-4'>
                    <form-date-time label="Ngày đánh giá gần nhất" :model="formModel" :errors="formErrors" attribute="evaluate_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
                <div class='col-md-4'>
                    <form-select label="Kết quả đánh giá hỗ trợ hòa nhập" :model="formModel" attribute="result" :errors="formErrors" :options="$params.patient.integrateOptions" />
                </div>
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
        <b-modal v-model="showFormView" size='xl' :title="formTitle" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md-4'>
                    <patient-select label="NKT được hỗ trợ hòa nhập" :disabled="true" :model="formModel" attribute="patient_id" :errors="formErrors"></patient-select>
                </div>
                <div class='col-md-4'>
                    <form-input label="Mã NKT" :disabled="true" :required='true' :model="formModel" attribute="code" :errors="formErrors"></form-input>
                </div>
                <div class='col-md-4'>
                    <form-input label="Họ và tên" :disabled="true" :required='true' :model="formModel" attribute="fullname"  :errors="formErrors" ></form-input>
                </div>
            </div>
            <div class='row'>
                <div class='col-md'>
                    <form-date-time label="Năm sinh" :disabled="true" :required='true' :model="formModel" :errors="formErrors" attribute="dob" format="YYYY" placeholder="YYYY" />
                </div>
                <div class='col-md'>
                    <form-select label="Giới tính" :disabled="true" :required='true' :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender" />
                </div>
                <div class='col-md'>
                    <parameter-select label="Dân tộc" :disabled="true" :type="$params.const.race.key" :model="formModel" attribute="race_id" />
                </div>
            </div> 
            <div class='row'>
                <div class='col-md'>
                    <form-group-address class="pr-1" :model="formModel" :errors="formErrors" :provinceDisabled='true' :districtDisabled='true' :wardDisabled="true" :provinceRequired='true' :districtRequired='true' :wardRequired='true'></form-group-address>
                </div>
            </div>
            <div class='row'>
                <div class='col-md'>
                    <form-select-multiple :close-on-select='false' :disabled="true" :required='true' label="Dạng khuyết tật" :model="formModel" attribute="types" :errors="formErrors" :options="patientTypeOptions" />
                </div>
                <div class='col-md'>
                    <form-select label="Mức độ khuyết tật" :disabled="true" :required='true' :model="formModel" attribute="degree_disability" :errors="formErrors" :options="$params.patient.degreeDisabilityOptions" />
                </div>
                <div class='col-md'>
                    <form-select label="Nạn nhân da cam" :disabled="true" :required='true' :model="formModel" attribute="agent_orange_victim" :errors="formErrors" :options="$params.patient.victimOptions" />
                </div>
            </div> 
            <div class='row'>
                <div class='col-md-4'>
                    <form-select label="Nạn nhân bom mìn" :disabled="formModel.patient_id" :required='false' :model="formModel" attribute="mine_victim" :errors="formErrors" :options="$params.patient.mineVictimOptions" />
                </div>
                <div class='col-md'>
                    <form-date-time label="Ngày bắt đầu can thiệp" :disabled="true" :model="formModel" :errors="formErrors" attribute="start_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
                <div class='col-md'>
                    <form-date-time label="Ngày can thiệp gần nhất" :required='true' :disabled="true" :model="formModel" :errors="formErrors" attribute="rate_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
            </div>
            <div class='row'>
                <div class='col-md-4'>
                    <form-date-time label="Ngày kết thúc can thiệp" :disabled="true" :model="formModel" :errors="formErrors" attribute="end_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
                <div class='col-md-4'>
                    <form-date-time label="Ngày đánh giá gần nhất" :disabled="true" :model="formModel" :errors="formErrors" attribute="evaluate_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
                 <div class='col-md-4'>
                    <form-select label="Kết quả đánh giá hỗ trợ hòa nhập" :disabled="true" :model="formModel" attribute="result" :errors="formErrors" :options="$params.patient.integrateOptions" />
                </div>
            </div>
            <template v-slot:modal-footer="{ cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import Dropdown from '@/components/Dropdown';
    import ClientSelect from '@/components/ClientSelect';
    import Log from "@/components/Log";
    import PatientSelect from '@/components/PatientSelect';
    import ParameterSelect from '@/components/ParameterSelect';
    import Import from './PatientIntegrateImport';
    import Export from './PatientIntegrateExport';
    import ImportMinxi from "@/mixins/Import";

    export default {
        components: {ClientSelect, Log, Dropdown, PatientSelect, ParameterSelect, Import, Export},
        mixins: [list, crud, role, ImportMinxi],
        data: function () {
            return {
                service: '/patient-integrate',
                reloadBus: "reloadPatientIntegrate",
                defaultFilterFormModel: {
                    client_id: this.isPrime() || this.isTW() || this.isUsaid() ? '' : this.$store.state.client.id,
                    patient_id: null,
                    result: null,
                    parameters: [
                        this.$params.const.race.key,
                    ],
                    mine: null,
                    client_sub_id: null,
                },
                defaultFormModel: {
                    types: null,
                    agent_orange_victim: null,
                    degree_disability: null,
                    code: null,
                    race_id: null,
                    gender_id: null,
                    patient_id: null,
                    evaluate_time: null,
                    province_id: null,
                    district_id: null,
                    ward_id: null,
                    result: null,
                    mine_victim: null,
                },
                patientTypeOptions: [],
                showFormView: false,
                abcd: null,
                lozbus:"reload",
                // template mẫu
                fileName: `ho_tro_hoa_nhap`,
                templateName: 'ho_tro_hoa_nhap',
                headers: [
                    {header: 'code', key: 'code',label: 'Mã NKT*', width: 30, desc: "Nhập định dạng chữ (BHYT/CCCD/CMT/Mã hồ sơ NKT - tối đa 100 ký tự)"},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên*', width: 30, desc: "Nhập định dạng chữ (tối đa 200 ký tự)"},
                    {header: 'dob', key: 'dob', label: 'Năm sinh*', width: 15, desc: "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)"},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 25, desc: "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện*', width: 25, desc: "Nhập định dạng chữ"},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường*', width: 25, desc: "Nhập định dạng chữ"},
                    {header: 'race_id', key: 'race_id', label: 'Dân tộc', width: 15, desc: "Nhập định dạng chữ"},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính*', width: 15, desc: "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)"},
                    {header: 'types', key: 'types', label: 'Dạng tật (6 nhóm, có để đa khuyết tật)*', width: 25, desc: "Nhập 01 hoặc nhiều giá trị số từ 1 đến 6 (Trong đó: 1. Khuyết tật vận động/2. Khuyết tật nghe, nói/3. Khuyết tật nhìn/4. Khuyết tật thần kinh, tâm thần/5. Khuyết tật trí tuệ/6. Khuyết tật khác)."},
                    {header: 'agent_orange_victim', key: 'agent_orange_victim', label: 'Nạn nhân da cam*', width: 25, desc: "Nhập 01 giá trị số từ 1 đến 3 (Trong đó: " + this.$params.patient.victimOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'degree_disability', key: 'degree_disability', label: 'Mức độ khuyết tật*', width: 25, desc: "Nhập 01 giá trị số từ 1 đến 4 (Trong đó: 1.Nhẹ/2.Nặng/3.Đặc biệt nặng/4. Không xác định)"},
                    {header: 'mine_victim', key: 'mine_victim', label: 'Nạn nhân bom mìn', width: 25, desc: "Nhập giá trị số từ 1 đến 2 (Trong đó: " + this.$params.patient.mineVictimOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'start_time', key: 'start_time', label: 'Ngày bắt đầu can thiệp', width: 25, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu"},
                    {header: 'rate_time', key: 'rate_time', label: 'Ngày can thiệp gần nhất*', width: 25, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp"},
                    {header: 'evaluate_time', key: 'evaluate_time', label: 'Ngày đánh giá gần nhất', width: 25, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp"},
                    {header: 'result', key: 'result', label: 'Kết quả đánh giá hỗ trợ hòa nhập', width: 25, desc: "Nhập giá trị số từ 1 đến 3 (Trong đó: " + this.$params.patient.integrateOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'end_time', key: 'end_time', label: 'Ngày kết thúc can thiệp', width: 25, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu và bằng hoặc sau ngày bắt đầu can thiệp"},
                ],
                indexRow: 4,
            };
        },
        computed: {
            fields() {
                let fields = [
                    {key: 'info', label: "Người khuyết tật"},
                    {key: 'types', label: "Thông tin khác"},
                    {key: 'result', label: "Kết quả đánh giá"},
                    {key: 'time', label: "Thời gian"},
                    {key: 'action', label: "Hành động", class: 'text-right'},
                ];
                if (this.isPrime() || this.isTW() || this.isUsaid()) {
                    return [{key: 'client_id', label: "Đơn vị"}].concat(fields);
                }
                return fields;
            },
        },
        watch: {
            "formModel.code": {
                handler(val) {
                    if (val) {
                        this.formModel.code = val.toUpperCase();
                    }
                }, deep: true
            }
        },
        methods: {
            searchClient() {
                this.filterFormModel.client_sub_id = null;
                this.filterFormModel.page = 1;
                this.load();
            },
            beforeUpdate(data) {
                data.dob = data.dob ? data.dob * 1000 : null;
                data.start_time = data.start_time ? data.start_time * 1000 : null;
                data.rate_time = data.rate_time ? data.rate_time * 1000 : null;
                data.evaluate_time = data.evaluate_time ? data.evaluate_time * 1000 : null;
                data.end_time = data.end_time ? data.end_time * 1000 : null;
            },
            changePatient(attribute) {
                this.formModel.fullname = null;
                this.formModel.dob = null;
                this.formModel.gender_id = null;
                this.formModel.race_id = null;
                this.formModel.types = null;
                this.formModel.ward_id = null;
                this.formModel.province_id = null;
                this.formModel.district_id = null;
                this.formModel.degree_disability = null;
                this.formModel.agent_orange_victim = null;
                this.formModel.mine_victim = null;
                this.formModel.types = null;
                if(attribute == 'patient_id'){
                    if(this.formModel.patient_id){
                        this.formModel.types = [];
                        this.$service.get(`patient/get?id=${this.formModel.patient_id}`).then(response => {
                            this.formModel.patient_id = response.data.id;
                            this.formModel.code = response.data.code;
                            this.formModel.fullname = response.data.fullname;
                            this.formModel.dob = response.data.dob ? response.data.dob * 1000 : null;
                            this.formModel.gender_id = response.data.gender_id;
                            this.formModel.race_id = response.data.race_id;
                            this.formModel.types = response.data.types;
                            this.formModel.province_id = response.data.province_id;
                            this.formModel.district_id = response.data.district_id;
                            this.formModel.ward_id = response.data.ward_id;
                            this.formModel.degree_disability = response.data.degree_disability;
                            this.formModel.agent_orange_victim = response.data.agent_orange_victim;
                            this.formModel.mine_victim = response.data.mine_victim;
                        })
                    } else {
                        this.formModel = Object.assign({}, this.defaultFormModel);
                    }
                } else if (attribute == 'code') {
                    if(this.formModel.code){
                        this.formModel.types = [];
                        this.$service.get(`patient/get-by-code?code=${this.formModel.code}`).then(response => {
                            this.formModel.patient_id = response.data.id;
                            this.formModel.code = response.data.code;
                            this.formModel.fullname = response.data.fullname;
                            this.formModel.dob = response.data.dob ? response.data.dob * 1000 : null;
                            this.formModel.gender_id = response.data.gender_id;
                            this.formModel.race_id = response.data.race_id;
                            this.formModel.types = response.data.types;
                            this.formModel.province_id = response.data.province_id;
                            this.formModel.district_id = response.data.district_id;
                            this.formModel.ward_id = response.data.ward_id;
                            this.formModel.degree_disability = response.data.degree_disability;
                            this.formModel.agent_orange_victim = response.data.agent_orange_victim;
                            this.formModel.mine_victim = response.data.mine_victim;
                    })
                    } else {
                        this.formModel = Object.assign({}, this.defaultFormModel);
                    }
                }
            },
            view: function (id) {
                let uri = this.service;
                if (id) {
                    uri += '/get?id=' + id;
                }
                this.updateLoading = true;
                this.$service.get(uri).then(response => {
                    this.formModel = {};
                    this.formErrors = {};
                    this.showFormView = true;
                    this.formModel = response.data;
                    this.formTitle = "Xem thông tin chăm sóc: " + this.formModel.fullname;
                    this.formModel.id = id;
                    this.formModel.dob = response.data.dob ? response.data.dob * 1000 : null;
                    this.formModel.start_time = response.data.start_time ? response.data.start_time * 1000 : null;
                    this.formModel.end_time = response.data.end_time ? response.data.end_time * 1000 : null;
                    this.formModel.evaluate_time = response.data.evaluate_time ? response.data.evaluate_time * 1000 : null;
                    this.formModel.rate_time = response.data.rate_time ? response.data.rate_time * 1000 : null;
                }).finally(() => {
                    this.updateLoading = false;
                });
            },
            getPatientTypeOption(){
                for (let i in this.$params.patient.typeOptions){
                    let id = this.$params.patient.typeOptions[i];
                    if(id.value != null){
                        this.patientTypeOptions.push(id);
                    }  
                }
                return this.patientTypeOptions;
            },
        },
        mounted(){
            this.getPatientTypeOption();
        },
    }
</script>