<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :placeholder="'Nhập mã, họ tên ...'" :items="items" :fields="fields" @load="load" @reset="reset"
                     title="Đào tạo người thân" titleIcon="fa fa-chalkboard-teacher">
                <template slot="filter">
                    <client-select v-if='isPrime() || isTW() ||  isUsaid()' attribute="client_id"  :model="filterFormModel" :errors="formErrors"  :filter="{parent_id: $store.state.client.id}" :is-filter='true' :placeHolder="isPrime() ? 'Chọn đơn vị thầu phụ' : 'Chọn đơn vị thầu chính'" class="mr-2" @change='searchClient' />
                    <client-select v-if='(isTW() ||  isUsaid()) && filterFormModel.client_id' attribute="client_sub_id"  :model="filterFormModel" :errors="formErrors" placeHolder="Chọn đơn vị thầu phụ"  :filter="{parent_id: filterFormModel.client_id}" :is-filter='true' class="mr-2" @change='search' />
                    <patient-select :model="filterFormModel" attribute="patient_id"  :is-filter='true' :filter="{client_id: filterFormModel.client_id}"  class="mr-2"  @change='search' />
                    <dropdown type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="relation"  :options="$params.patientRelative.relationshipOptions" :isFilter="true" @change="search" />
                    <province-select :model="filterFormModel" attribute="province_id"  :is-filter='true'  class="mr-2"  @change='search' />
                    <time-range-picker class="mr-1" :model="filterFormModel" label="Ngày kết thúc đào tạo" fromAttribute="from_end_time" toAttribute="to_end_time" :defaultShortcut="10" @change="search" />
                </template>
                <template #cell(client_id)="{item}">
                    <span v-if='params.clients[item.client_id]' >{{params.clients[item.client_id].short_name}}</span>
                </template>
                <template #cell(detail)="{item}">
                    <div v-if="item.patient_relative_id && params.patient_relative_id[item.patient_relative_id] != null"><span class="small text-gray-999">Mã: </span>{{params.patient_relative_id[item.patient_relative_id].code}}</div>
                    <div v-if="item.patient_relative_id && params.patient_relative_id[item.patient_relative_id] != null"><span class="small text-gray-999">Họ và tên: </span>{{params.patient_relative_id[item.patient_relative_id].fullname}}</div>
                    <div v-if="item.patient_relative_id && params.patient_relative_id[item.patient_relative_id].relation != null"><span class="small text-gray-999">Mối quan hệ với NKT: </span>{{ $params.patientRelative.relationshipOptions.find(o => o.value === params.patient_relative_id[item.patient_relative_id].relation).text }}</div>
                    <div v-if="item.patient_relative_id && params.patient_relative_id[item.patient_relative_id].dob != null"><span class="small text-gray-999">Năm sinh: </span>{{params.patient_relative_id[item.patient_relative_id].dob}}</div>
                    <div v-if="item.patient_relative_id && params.patient_relative_id[item.patient_relative_id].gender_id != null"><span class="small text-gray-999">Giới tính: </span>{{params.patient_relative_id[item.patient_relative_id].gender_id == 0 ? 'Nam' : params.patient_relative_id[item.patient_relative_id].gender_id == 1 ? 'Nữ' : 'Khác'}}</div>
                </template>
                <template #cell(patient)="{item}">
                    <div v-if="params.patient_id[params.patient_relative_id[item.patient_relative_id].patient_id] != null"><span class="small text-gray-999">Mã số NKT: </span>{{params.patient_id[params.patient_relative_id[item.patient_relative_id].patient_id].code}}</div>
                    <div v-if="params.patient_id[params.patient_relative_id[item.patient_relative_id].patient_id] != null"><span class="small text-gray-999">Tên NKT: </span>{{params.patient_id[params.patient_relative_id[item.patient_relative_id].patient_id].fullname}}</div>
                </template>
                <template #cell(time)="{item}">
                    <div v-if="item.start_time"><span class="small text-gray-999">Ngày bắt đầu: </span>{{item.start_time * 1000 |  moment('DD/MM/YYYY')}}</div>
                    <div v-if="item.end_time"><span class="small text-gray-999">Ngày kết thúc: </span>{{item.end_time * 1000 |  moment('DD/MM/YYYY')}}</div>
                </template>
                <template slot="action">
                    <export :filter="filterFormModel" :params='params' :prime="isPrime() || isTW() || isUsaid()" :totalCount="itemCount" class="mr-1"/>
                    <g-button class="ml-1 btn btn-gray btn-sm" icon="fa fa-file-excel" v-if="isSub()" :loading="saveLoading" size="sm" variant="success" content="Tải xuống tập tin mẫu" @click="downloadTemplate()" />
                    <import v-if="isSub() && permissions('patient-relative-train_import')" :client_id="params.clientId" :params='params' class="mr-1"/>
                    <g-button class="ml-2" v-if="permissions('patient-relative-train_create') && isSub()" :loading="saveLoading" size="sm" variant="success" icon="plus" content="Thêm mới" @click='create' />
                </template>
                <template #cell(action)="{item}">
                    <g-button v-if="permissions('patient-relative-train_view')" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-eye" @click="view(item.id)" />
                    <g-button v-if="permissions('patient-relative-train_update') && isSub()" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="pencil"  @click="update(item.id)" />
                    <g-button  v-if="permissions('patient-relative-train_del') && isSub()" :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)' />
                    <log type="patient-relative-train" :targetId="item.id" title="Lịch sử" :isStaff="true" class="ml-1 mb-1" ></log>
                </template>
            </g-table>
        </div>
        <b-modal v-model="showForm" size='xl' :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <!-- <div class='col-md-4'>
                    <patient-relative-select label="Người thân" :disabled="formModel.id || formModel.disableLabelRelative" :model="formModel" :filter="{parent_id: $store.state.client.id}" attribute="patient_relative_id" @change="changePatientRelative('patient_relative_id')"  :errors="formErrors"></patient-relative-select>
                </div> -->
                <div class='col-md-4'>
                    <patient-select label="NKT được chăm sóc" :required='false' :model="formModel" :filter="{parent_id: $store.state.client.id}" attribute="patient_id" @change="changePatientRelative('patient_id')" :errors="formErrors"></patient-select>
                </div>
                <div class='col-md-4'>
                    <form-select label="Mối quan hệ với NKT" :required='false' :model="formModel" attribute="relation" :errors="formErrors" :options="$params.patientRelative.relationshipOptions" />
                </div>
            </div>
            <div class="row">
                <div class='col-md-4'>
                    <form-input label="Mã người thân" :disabled="formModel.patient_relative_id"  :required='true' :model="formModel" attribute="code" @change="changePatientRelative('code')" :errors="formErrors"></form-input>
                </div>
                  <div class='col-md-4'>
                    <form-input label="Họ và tên người thân" :required='true' :model="formModel" attribute="fullname"  :errors="formErrors" ></form-input>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-4'>
                    <form-input label="Năm sinh"  type="number" :required='true' :model="formModel" attribute="dob"  :errors="formErrors" placeholder="YYYY"></form-input>
                </div>
                <div class='col-md'>
                    <form-select label="Giới tính"  :required='true' :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender" />
                </div>
                 <div class='col-md'>
                    <parameter-select label="Dân tộc"  :type="$params.const.race.key" :model="formModel" attribute="race_id" />
                </div>
            </div> 
            <div class='row'>
                <div class='col-md'>
                    <form-group-address class="pr-1" :model="formModel" :errors="formErrors" :provinceRequired='true' :districtRequired='true' :wardRequired='true'></form-group-address>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-4'>
                    <form-date-time label="Ngày bắt đầu đào tạo" :required='true' :model="formModel" :errors="formErrors" attribute="start_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
                <div class='col-md-4'>
                    <form-date-time label="Ngày kết thúc đào tạo" :model="formModel" :errors="formErrors" attribute="end_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
        <b-modal v-model="showFormView" size='xl' :title="formTitle" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <!-- <div class='col-md-4'>
                    <patient-relative-select label="Người thân" :disabled="true" :model="formModel" :filter="{parent_id: $store.state.client.id}" attribute="patient_relative_id" @change="changePatientRelative()"  :errors="formErrors"></patient-relative-select>
                </div> -->
                 <div class='col-md-4'>
                    <patient-select label="NKT được chăm sóc" :disabled="true" :required='false' :model="formModel" :filter="{parent_id: $store.state.client.id}" attribute="patient_id" :errors="formErrors"></patient-select>
                </div>
                <div class='col-md-4'>
                    <form-select label="Mối quan hệ với NKT" :disabled="true" :required='false' :model="formModel" attribute="relation" :errors="formErrors" :options="$params.patientRelative.relationshipOptions" />
                </div>
            </div>
            <div class="row">
                 <div class='col-md-4'>
                    <form-input label="Mã người thân" :disabled="true" :required='true' :model="formModel" attribute="code" :errors="formErrors"></form-input>
                </div>
                <div class='col-md-4'>
                    <form-input label="Họ và tên người thân" :disabled="true" :required='true' :model="formModel" attribute="fullname"  :errors="formErrors" ></form-input>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-4'>
                    <form-input label="Năm sinh" type="number" :required='true' :disabled="true" :model="formModel" attribute="dob"  :errors="formErrors" placeholder="YYYY"></form-input>
                </div>
                <div class='col-md'>
                    <form-select label="Giới tính" :disabled="true" :required='true' :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender" />
                </div>
                <div class='col-md'>
                    <parameter-select label="Dân tộc" :disabled="true" :type="$params.const.race.key" :model="formModel" attribute="race_id" />
                </div>
            </div> 
            <div class='row'>
                <div class='col-md'>
                    <form-group-address class="pr-1" :provinceDisabled="true" :districtDisabled="true" :wardDisabled="true" :model="formModel" :errors="formErrors" :provinceRequired='true' :districtRequired='true' :wardRequired='true'></form-group-address>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-4'>
                    <form-date-time label="Ngày bắt đầu đào tạo" :disabled="true" :required='true' :model="formModel" :errors="formErrors" attribute="start_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
                <div class='col-md-4'>
                    <form-date-time label="Ngày kết thúc đào tạo" :disabled="true" :model="formModel" :errors="formErrors" attribute="end_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
            </div>
            <template v-slot:modal-footer="{ cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import ClientSelect from '@/components/ClientSelect';
    import Log from "@/components/Log";
    import PatientSelect from '@/components/PatientSelect';
    import ParameterSelect from '@/components/ParameterSelect';
    import ProvinceSelect from '@/components/ProvinceSelect'; 
    import Import from './PatientRelativeTrainImport';
    import Export from './PartientRelativeTrainExport';
    import Dropdown from '@/components/Dropdown';
    import ImportMinxi from "@/mixins/Template";
    
    export default {
        components: {ClientSelect, Log, ParameterSelect, Import, Export, PatientSelect, Dropdown, ProvinceSelect},
        mixins: [list, crud, role, ImportMinxi],
        data: function () {
            return {
                service: '/patient-relative-train',
                reloadBus: "reloadPatientRelativeTrain",
                defaultFilterFormModel: {
                    client_id: this.isPrime() || this.isTW() || this.isUsaid() ? '' : this.$store.state.client.id,
                    intervention: null,
                    patient_id: null,
                    parameters: [
                        this.$params.const.race.key,
                    ],
                    // keyword: '',
                    relation: null,
                    client_sub_id: null,
                },
                defaultFormModel: {
                    race_id: null,
                    gender_id: null,
                    patient_relative_id: null,
                    patient_id: null,
                    province_id: null,
                    district_id: null,
                    ward_id: null,
                    relation: null,
                    code: null,
                    disableLabelRelative: false,
                    disableLabelPatient: false,
                },
                showFormView: false,
                //template mẫu
                fileName: `dao_tao_nguoi_than.xlsx'`,
                templateName: 'dao_tao_nguoi_than.xlsx',
                headers: [
                    {header: 'code', key: 'code',label: 'Mã số thành viên gia đình*', width: 30 , desc: "Nhập định dạng chữ (BHYT/CCCD/CMT/Mã hồ sơ NKT - tối đa 100 ký tự)"},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên*', width: 30, desc: "Nhập định dạng chữ (tối đa 200 ký tự)"},
                    {header: 'dob', key: 'dob', label: 'Năm sinh*', width: 15, desc: "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)"},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 25, desc: "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện*', width: 25, desc: "Nhập định dạng chữ"},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường*', width: 25, desc: "Nhập định dạng chữ"},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính*', width: 15, desc: "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)"},
                    {header: 'race_id', key: 'race_id', label: 'Dân tộc', width: 15, desc: "Nhập định dạng chữ"},
                    {header: 'relation', key: 'relation', label: 'Mối quan hệ với NKT', width: 25, desc: "Nhập 01 giá trị số từ 1 đến 4 ( Trong đó: 1.Bố mẹ/2. Vợ chồng/3. Con ruột dâu, rể/4. Khác)"},
                    {header: 'start_time', key: 'start_time', label: 'Ngày bắt đầu đào tạo*', width: 25, desc: "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu"},
                    {header: 'end_time', key: 'end_time', label: 'Ngày kết thúc đào tạo', width: 25, desc: "Nhập định dạng ngày/tháng/năm (dd/mm/yyyy)"},
                    {header: 'patient_code', key: 'patient_code', label: 'Mã số NKT được chăm sóc', width: 25, desc: "Nhập định dạng chữ"},
                ],
                indexRow: 4,
            };
        },
        computed: {
            fields() {
                let fields = [
                    {key: 'patient', label: "NKT được chăm sóc"},
                    {key: 'detail', label: "Người thân"},
                    {key: 'time', label: "Thời gian đào tạo"},
                    {key: 'action', label: "Hành động", class: 'text-right'},
                ];
                if (this.isPrime() || this.isTW() || this.isUsaid()) {
                    return [{key: 'client_id', label: "Đơn vị"}].concat(fields);
                }
                return fields;
            },
        },
        watch: {
            "formModel.code": {
                handler(val) {
                    if (val) {
                        this.formModel.code = val.toUpperCase();
                    }
                }, deep: true
            }
        },
        methods: {
            searchClient() {
                this.filterFormModel.client_sub_id = null;
                this.filterFormModel.page = 1;
                this.load();
            },
            beforeUpdate(data) {
                data.start_time = data.start_time ? data.start_time * 1000 : null;
                data.end_time = data.end_time ? data.end_time * 1000 : null;
                data.code = data.code ? data.code.toUpperCase() : null;
            },
            changePatientRelative(attribute) {
                this.formModel.race_id = null;
                if(attribute == 'patient_relative_id'){
                    this.formModel.province_id = null;
                    this.formModel.district_id = null;
                    this.formModel.ward_id = null;
                    if (this.formModel.patient_relative_id) {
                        this.$service.get(`patient-relative-other/get?id=${this.formModel.patient_relative_id}`).then(response => {
                            if(response.data){
                                this.formModel = Object.assign({}, this.defaultFormModel);
                                this.formModel.code = response.data.code;
                                this.formModel.patient_relative_id = response.data.id;
                                this.formModel.fullname = response.data.fullname;
                                this.formModel.dob = response.data.dob ? response.data.dob : null;
                                this.formModel.gender_id = response.data.gender_id;
                                this.formModel.race_id = response.data.race_id;
                                this.formModel.province_id = response.data.province_id;
                                this.formModel.district_id = response.data.district_id;
                                this.formModel.ward_id = response.data.ward_id;
                                this.formModel.relation = response.data.relation;
                                this.formModel.patient_id = response.data.patient_id;
                                this.formModel.disableLabelPatient = true;
                            }
                        })
                    } else {
                        this.formModel.patient_relative_id = null;
                        this.formModel.patient_id = null;
                        this.formModel = Object.assign({}, this.defaultFormModel);
                    }
                } else if (attribute == 'patient_id') {
                    if(this.formModel.patient_id){
                        this.formModel.disableLabelRelative = true;
                        this.formModel.patient_relative_id = null;
                    }
                } else if (attribute == 'code'){
                    this.formModel.province_id = null;
                    this.formModel.district_id = null;
                    this.formModel.ward_id = null;
                    if(this.formModel.code){
                        this.formModel.patient_relative_id = null;
                        this.$service.get(`patient-relative-other/get-by-code?code=${this.formModel.code}`).then(response => {
                            if(response.data){
                                this.formModel.patient_id = null;
                                this.formModel.code = response.data.code;
                                this.formModel.patient_relative_id = response.data.id;
                                this.formModel.fullname = response.data.fullname;
                                this.formModel.dob = response.data.dob ? response.data.dob : null;
                                this.formModel.gender_id = response.data.gender_id;
                                this.formModel.race_id = response.data.race_id;
                                this.formModel.province_id = response.data.province_id;
                                this.formModel.district_id = response.data.district_id;
                                this.formModel.ward_id = response.data.ward_id;
                                this.formModel.relation = response.data.relation;
                                this.formModel.patient_id = response.data.patient_id;
                                this.formModel.disableLabelPatient = true;
                            }
                        })
                    } else {
                        this.formModel = Object.assign({}, this.defaultFormModel);
                    }
                }
            },
            view: function (id) {
                let uri = this.service;
                if (id) {
                    uri += '/get?id=' + id;
                }
                this.updateLoading = true;
                this.$service.get(uri).then(response => {
                    this.formModel = {};
                    this.formErrors = {};
                    this.showFormView = true;
                    this.formModel = response.data;
                    this.formTitle = "Xem thông tin đào tạo người thân: " + this.formModel.fullname;
                    this.formModel.id = id;
                    this.formModel.dob = response.data.dob ? response.data.dob : null;
                    this.formModel.start_time = response.data.start_time ? response.data.start_time * 1000 : null;
                    this.formModel.end_time = response.data.end_time ? response.data.end_time * 1000 : null;
                }).finally(() => {
                    this.updateLoading = false;
                });
            },
        }
    }
</script>