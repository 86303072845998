<template>
    <div>
        <div class="row">
            <div class="col-12">
                <g-loading v-if="firstLoading" />
                <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :placeholder="'Nhập mã, họ tên...'" :items="items" :fields="fields" @load="load" @reset="reset"
                         title="Người khuyết tật" titleIcon="fa fa-user-injured">
                    <template slot="filter">
                        <client-select v-if='isPrime()' attribute="client_id" :filter="{parent_id: $store.state.client.id}" :model="filterFormModel" :errors="formErrors" :is-filter='true' class="mr-2" @change='search'/>
                        <province-select :model="filterFormModel" attribute="province_id"  :is-filter='true'  class="mr-2"  @change='load' />
                        <dropdown   :right-menu="true" type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="gender_id"  :options="$params.gender" :isFilter="true" @change="search" />
                    </template>
                    <template slot="action">
                        <export :filter="filterFormModel" :totalCount="itemCount" :edu="edu" :params="params" class="mr-1"/>
                        <g-button class="btn btn-gray btn-sm" icon="fa fa-file-excel" v-if='isSub()' :loading="saveLoading" size="sm" variant="success" content="Tải xuống tập tin mẫu" @click="downloadTemplate()" />
                        <import v-if="isSub() && permissions('edu-student_import')" :params='params' :edu="edu" class="mr-1"/>
                        <g-button v-if="permissions('edu-student_create') && isSub()"  :loading="saveLoading" size="sm" class="mr-1" variant="success" icon="fa fa-plus" content="Thêm mới" @click="create" />
                    </template>
                    <template #cell(fullname)="{item}">
                        <div ><span class="small text-gray-999">Mã: </span>{{item.code}}</div>
                        <div ><span class="small text-gray-999">Họ và tên: </span>{{item.fullname}}</div>
                    </template>
                    <template #cell(province_id)="{item}">
                        <div v-if='params.provinces[item.province_id]'  >
                            <span>{{params.provinces[item.province_id].name}}</span>
                        </div>
                    </template>
                    <template #cell(other)="{item}">
                        <div ><span class="small text-gray-999">Năm sinh: </span>{{item.year}}</div>
                        <div><span class="small text-gray-999">Giới tính: </span>{{$params.gender.find(o=> o.value === item.gender_id).text}}</div>
                    </template>
                    <template #cell(action)="{item}">
                        <g-button :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-eye" @click="view(item.id)" />
                        <g-button v-if="isSub()" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="pencil"  @click="update(item.id)" />
                        <log type="edu-student" :targetId="item.id" title="Lịch sử" :isStaff="true" class="ml-1 mb-1" ></log>
                        <g-button  v-if="isSub()" :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)' />
                    </template>
                </g-table>
            </div>
        </div>
        <b-modal v-model="showForm" size='lg' :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md-6'>
                    <patient-select label="Người khuyết tật" :required='false'  :disabled="formModel.id"  :model="formModel" @change="changePatient()" attribute="patient_id" :errors="formErrors"></patient-select>
                </div>
            </div>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Mã NKT" :disabled="formModel.id" :required='true' :model="formModel" attribute="code" :errors="formErrors"></form-input>
                </div>
                <div class='col-md'>
                    <form-input label="Họ và tên" :required='true' :model="formModel" attribute="fullname"  :errors="formErrors" ></form-input>
                </div>
            </div>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Năm sinh"  placeholder="YYYY" :required='true' :model="formModel" attribute="year" type="number" :errors="formErrors"></form-input>
                </div>
                <div class='col-md'>
                    <form-select label="Giới tính" :required='true' :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender" />
                </div>
            </div> 
            <div class='row'>
                <div class='col-md-6'>
                    <province-select label="Tỉnh/Thành phố"  :required='true' :model="formModel" attribute="province_id" :errors="formErrors"/>
                </div>
            </div>
            <template v-slot:modal-footer>
                <b-button variant="secondary" class="float-right" @click="showForm = false">Đóng</b-button>
                <b-button variant="primary" class="float-right" @click="save()" :disabled="saveLoading">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
        <b-modal v-model="showFormView" size='lg' :title="formTitle"   cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md-6'>
                    <patient-select label="Người khuyết tật"  :disabled="true" :required='false' :model="formModel" @change="changePatient()" attribute="patient_id" :errors="formErrors"></patient-select>
                </div>
            </div>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Mã NKT" :disabled="true" :required='true' :model="formModel" attribute="code" :errors="formErrors"></form-input>
                </div>
                <div class='col-md'>
                    <form-input label="Họ và tên" :required='true' :disabled="true" :model="formModel" attribute="fullname"  :errors="formErrors" ></form-input>
                </div>
            </div>
            <div class='row'>
                <div class='col-md'>
                    <form-input label="Năm sinh"  placeholder="YYYY" :disabled="true" :required='true' :model="formModel" attribute="year" type="number" :errors="formErrors"></form-input>
                </div>
                <div class='col-md'>
                    <form-select label="Giới tính" :required='true' :disabled="true" :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender" />
                </div>
            </div> 
            <div class='row'>
                <div class='col-md-6'>
                    <province-select label="Tỉnh/Thành phố" :disabled="true"  :required='true' :model="formModel" attribute="province_id" :errors="formErrors"/>
                </div>
            </div>
            <template v-slot:modal-footer="{ cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
            </template>
        </b-modal>
        <div class="form-group text-right">
            <b-button variant="secondary" class="ml-1" @click="$emit('close')">
                Đóng
            </b-button>
        </div>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import ProvinceSelect from '@/components/ProvinceSelect';
    import Log from "@/components/Log";
    import Export from './EduStudentEventExport';
    import Dropdown from '@/components/Dropdown';
    import Import from './EduStudentEventImport.vue';
    import moment from 'moment';
    import PatientSelect from '@/components/PatientSelect';
    import ImportMinxi from "@/mixins/Template";

    export default {
        props: ["edu"],
        components: {ProvinceSelect, Log, Dropdown, Export, Import, PatientSelect},
        mixins: [list, crud, role, ImportMinxi],
        data: function () {
            return {
                reloadBus: "reloadEduStudentEvent",
                service: '/edu-student',
                defaultFilterFormModel: {
                    edu_id: this.edu.id,
                    keyword: '',
                    gender_id: null,
                    province_id: null,
                    year: null
                },
                defaultFormModel: {
                    edu_id: this.edu.id,
                    fullname: null,
                    types: null,
                    gender_id: null,
                    patient_id: null,
                    year: null,
                    province_id: null,
                    code: null,
                },
                showFormView: null,
                fileName: `hoat_dong_su_kien_hs`,
                templateName: 'hoat_dong_su_kien_nkt.xlsx',
                headers: [
                    {header: 'fullname', key: 'fullname', label: 'Tên NKT tham gia*', width: 30, desc: "Nhập định dạng chữ (tối đa 200 ký tự)"},
                    {header: 'code', key: 'code', label: 'Mã định danh*', width: 30, desc: "Nhập định dạng chữ (BHYT/CCCD/CMT)"},
                    {header: 'year', key: 'year', label: 'Năm sinh*', width: 10, desc: "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)"},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính*', width: 10, desc: "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)"},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 25, desc: "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                ],
                indexRow: 4,
            };
        },
        watch: {
            "formModel.code": {
                handler(val) {
                    if (val) {
                        this.formModel.code = val.trim().toUpperCase();
                    }
                }, deep: true
            }
        },
        computed: {
            fields() {
                let fields = [{key: 'fullname', label: "Tên NKT"},
                    {key: 'other', label: "Thông tin khác"},
                    {key: 'province_id', label: "Tỉnh"},
                    {key: 'action', label: "Hành động", class: 'text-right'}, ];
                return fields;
            },
        },
        methods: {
            view: function (id) {
                let uri = this.service;
                if (id) {
                    uri += '/get?id=' + id;
                }
                this.updateLoading = true;
                this.$service.get(uri).then(response => {
                    if (this.beforeUpdate) {
                        this.beforeUpdate(response.data);
                    }
                    this.formModel = {};
                    this.formErrors = {};
                    this.showFormView = true;
                    this.formModel = response.data;
                    this.formTitle = "Xem thông tin NKT: " + this.formModel.fullname;
                    this.formModel.id = id;
                }).finally(() => {
                    this.updateLoading = false;
                });
            },
            changePatient() {
                if (this.formModel.patient_id) {
                    this.$service.get(`patient/get?id=${this.formModel.patient_id}`).then(response => {
                        this.formModel.patient_id = response.data.id;
                        this.formModel.code = response.data.code;
                        this.formModel.fullname = response.data.fullname;
                        this.formModel.gender_id = response.data.gender_id;
                        this.formModel.year = response.data.dob ? moment(response.data.dob * 1000).format('YYYY') : null;
                        this.formModel.province_id = response.data.province_id;
                    }).catch(error => {
                        if (error.response.data.message) {
                            this.$swal({
                                text: error.response.data.message,
                                icon: 'error'
                            });
                        }
                    })
                }
            },
        },
        mounted() {
        }
    }
</script>